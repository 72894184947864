import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from 'components/layout';
import AboutContainer from 'components/aboutContainer';
import Head from 'components/head';
import PageContainer from 'components/pageContainer';
import Img from 'gatsby-image';
import MEDIA from '../helpers/mediaTemplates';
import Footer from 'components/footer';
import SectionHeader from 'components/sectionHeader';

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 4em;
  ${MEDIA.DESKTOP`
    padding: 2em 0em;
  `}
`;

const StyledImg = styled(Img)`
  width: 50%;
  ${MEDIA.DESKTOP`
    width: 100%;
  `}
`;

const About = ({ data }) => (
  <Layout>
    <Head pageTitle={data.aboutJson.title} />
    <SectionHeader>{data.aboutJson.title}</SectionHeader>
    <PageContainer>
      <AboutContainer>
        <StyledImg fluid={data.aboutJson.profile.childImageSharp.fluid} />
        <TextBlock
          dangerouslySetInnerHTML={{
            __html: data.aboutJson.content.childMarkdownRemark.html,
          }}
        />
      </AboutContainer>
    </PageContainer>
    <Footer />
  </Layout>
);

About.propTypes = {
  data: PropTypes.object.isRequired,
};

export default About;

export const query = graphql`
  query AboutQuery {
    aboutJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      profile {
        childImageSharp {
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
